<template>
  <div class="div-box">
    <v-list ref="list" :isAdd="true" :headers="headers" :tableUrl="tableUrl" :searchParam.sync="searchParam">
      <template #headBtnSlot>
        <v-button text="新增" @click="addOnClick"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="公共设施名称" v-model="searchParam.facilitiesName" placeholder="请输入公共设施名称" clearable />
        <v-select
          clearable
          label="设施类别"
          placeholder="请选择设施类别"
          :options="facilitiesCategoryOptions"
          v-model="searchParam.facilitiesType"
          @change="$refs.list.search()"
        />
        <v-select
          clearable
          label="服务类别"
          placeholder="请选择服务类别"
          :options="serviceCategoryOptions"
          v-model="searchParam.serviceType"
          @change="$refs.list.search()"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="editOnClick(scope.row)" />
        <v-button text="删除" type="text" @click="deleteOnClick(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getServiceCategoryDictUrl, getSqyztListUrl, deleteSqyztInfoUrl } from './api.js';
import { whetherMap, facilitiesCategoryMap, serviceCategoryMap } from './map.js';
import { createAlinkVNode, createImgVNode, createHTMLVNode } from '@/utils/utils.js';
import { mapState } from 'vuex';

export default {
  name: 'CommunityAMap',
  data() {
    return {
      facilitiesCategoryOptions: [],
      facilitiesCategoryMap: {},
      serviceCategoryOptions: [],
      serviceCategoryMap: {},
      searchParam: {
        facilitiesName: '',
        facilitiesType: '',
        serviceType: '',
        tenantId: ''
      },
      tableUrl: getSqyztListUrl,
      headers: [
        {
          prop: 'id',
          label: '设施编号',
          formatter: (row, prop) => {
            let oBtn = `<span>${row[prop]}</span>`;
            if (row.source === 2) {
              oBtn += `<img style='width:20px;height:20px;vertical-align: middle;' src='${require('./img/icon_sptdr.png')}'></img>`;
            }
            return createHTMLVNode(this, oBtn, row, prop, { color: '#606266' });
          }
        },
        {
          prop: 'facilitiesName',
          label: '公共设施名称'
        },
        {
          prop: 'facilitiesType',
          label: '设施类别',
          formatter: (row, prop) => this.facilitiesCategoryMap[row[prop]]
        },
        {
          prop: 'serviceType',
          label: '服务类别',
          formatter: (row, prop) => this.serviceCategoryMap[row[prop]]
        },
        {
          prop: 'isUseActivity',
          label: '是否为活动场地',
          formatter: (row, prop) => whetherMap[row[prop]]
        },
        {
          prop: 'isUseSpace',
          label: '是否为可预约空间',
          formatter: (row, prop) => whetherMap[row[prop]]
        }

        // {
        //   prop: 'avatarUrl',
        //   label: '图片',
        //   formatter: (row, prop) => createImgVNode(this, row, prop)
        // },
      ]
    };
  },
  computed: {
    ...mapState('app', ['userInfo'])
  },
  created() {
    this.searchParam.tenantId = this.userInfo.tenantId;
    Promise.all([this.$axios.get(getServiceCategoryDictUrl)])
      .then(dictRes => {
        const [ssfuDict] = dictRes;
        ssfuDict.data = ssfuDict.data || {};
        ssfuDict.data[1] = Array.isArray(ssfuDict.data[1]) ? ssfuDict.data[1] : [];
        ssfuDict.data[2] = Array.isArray(ssfuDict.data[2]) ? ssfuDict.data[2] : [];
        this.facilitiesCategoryOptions = ssfuDict.data[1].map(item => ({ label: item.typeName, value: item.typeCode }));
        this.serviceCategoryOptions = ssfuDict.data[2].map(item => ({ label: item.typeName, value: item.typeCode }));
        this.facilitiesCategoryMap = Object.fromEntries(this.facilitiesCategoryOptions.map(item => [item.value, item.label]));
        this.serviceCategoryMap = Object.fromEntries(this.serviceCategoryOptions.map(item => [item.value, item.label]));
      })
      .finally(() => {});
  },
  methods: {
    addOnClick() {
      this.$router.push({
        name: 'CommunityAMapAdd'
      });
    },
    editOnClick(row) {
      this.$router.push({
        name: 'CommunityAMapEdit',
        query: {
          id: row.id
        }
      });
    },
    deleteOnClick(row) {
      this.$confirm('确定删除吗？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { id: row.id };
        this.$axios.post(deleteSqyztInfoUrl, this.$qs.stringify(params)).then(res => {
          if (res.code === 200) {
            this.$message.success('操作成功');
            this.$refs.list.search();
          }
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.div-box {
  box-sizing: border-box;
  height: 100%;
}
</style>
